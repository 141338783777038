<script setup lang="ts">
/**
 * SelectableCardGroup Component
 *
 * This component acts as a container for SharedSelectableCard components, ensuring that only one card
 * can be selected at a time.
 *
 * You can bind to selectedCardId to get the current selected card id:
 *
 * const selectedCardId = ref("card1");
 *
 * <SharedSelectableCardGroup v-model:selectedCardId="selectedCardId">
 *     <SharedSelectableCard id="card1">
 *         <div>I am card content 1</div>
 *     </SharedSelectableCa
 *     <SharedSelectableCard id="card2">
 *         <div>I am card content 2</div>
 *     </SharedSelectableCard>
 * </SharedSelectableCardGroup>
 */

const props = defineProps<{
  selectedCardId?: string;
}>();

const emit = defineEmits(["update:selectedCardId"]);

// Currently selected card
const selectedCardId = ref<string | undefined>(
  props.selectedCardId || undefined,
);

function updateSelectedCard(id: string) {
  selectedCardId.value = id;
  emit("update:selectedCardId", selectedCardId.value);
}

// Provide the selectedCardId and update function to child components
provide("selectedCardId", selectedCardId);
provide("updateSelectedCard", updateSelectedCard);
defineExpose({ updateSelectedCard });
</script>

<template>
  <div>
    <!-- Slot to inject SharedSelectableCard components -->
    <slot></slot>
  </div>
</template>
